@import "../01_Settings/settings.colors";

h1 {
  color: $primary;
}
.title--fixed {
  position: fixed;
}
.title--light {
  color: lightgray;
}
.title--end {
  text-align: end;
}
