.icon--floating-right,
.icon-group--floating-right {
  right: 5px;
}

.icon--floating-left,
.icon-group--floating-left {
  left: 5px;
}
.icon--floating-bottom,
.icon-group--floating-bottom {
  bottom: 5px;
}
.icon--floating-top,
.icon-group--floating-top {
  top: 5px;
}
.icon-group--floating {
  position: absolute;
}
