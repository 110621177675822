.card-list {
  display: flex;
  position: relative;
  flex-direction: column;
  &-dense {
    margin: 5px;
  }
  &-large {
    margin: 40px;
  }
}
.card-list__item {
  position: relative;
  &--wide {
    width: 500px;
  }
  &--wider {
    width: 600px;
  }
  &--normal {
    margin: 15px;
  }
  &--spacious {
    margin: 26px;
  }
}
