@import "../01_Settings/_settings.colors.scss";
form {
  background-color: white;
  flex-direction: column;
  width: fit-content;

  margin: 2em;
  border: transparent;
  border-radius: 5px;
  legend {
    font-weight: bold;
    font-size: 1.1em;
    color: $primary;
  }
}

.form-row {
  width: inherit;
  justify-content: space-between;
  align-items: center;
  * {
    padding-top: 0;
  }
}
