.container {
  display: flex;
  &-row {
    flex-direction: row;
  }
  &-column {
    flex-direction: column;
  }
}

.is-60 {
  width: 60%;
}
.block {
  display: block;
}
