@use "sass:list";

$blue: #e9f4f6, #daecf1, #b5dae3, #6bb5c7, #388294, #1c414a;
$grey: #f2f2f2, #bdb9bb, #918b8d, #655d60, #4f4649, #23181c, #0d0106;
$green: #c6f4e4, #8aefcc, #1fa073;
$red: #f4b5bd, #c76774, #ae5460;
$orange: #f1e1c1, #f7ba39, #ae8124;
$white: #ffffff;

$primary: nth($blue, 3);
$secondary: nth($blue, 4);
$Tertiary: nth($blue, 5);
$info: nth($green, 2);
$warning: nth($orange, 2);
$danger: nth($red, 2);
